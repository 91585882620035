$(document).ready(function () {

    /*
    STICKY MENU
    */
    $('header').sticky({
        topSpacing:0,
        zIndex : 100
    });

    $(document).scroll(function () {
        if ($(document).scrollTop() > 100) {
            $("header").addClass("scrollingdown");
        } else if ($(document).scrollTop() === 0) {
            $("header").removeClass("scrollingdown");
        }
    });

    /*
    MENU RESPONSIVE
     */
    $('#menu').on('click', function() {
        /*Ouvre le menu*/
        $('#nav').slideToggle();

        /*Change le picto*/
        let src = $(this).attr('src');
        let newsrc = (src == '/img/icones/menu.svg') ? '/img/icones/close.svg' : '/img/icones/menu.svg';
        $(this).attr('src', newsrc);

        /* Remonte tous les sous-menu */
        $('nav ul li > ul').slideUp();
    })
    /* Ouverture des sous-menu */
    if ($(window).width() < 1025) {
        $('#nav ul li a').on('click', function (e) {
            if ($(this).siblings('ul').length > 0) {
                e.preventDefault();
                $(this).siblings('ul').slideToggle();
                $(this).unbind("click");
            }
        })
    }


    $(document).scroll(function () {
        $('.to-top').fadeIn(100);
    });

    $('.to-top').on('click', function () {
        $("html, body").animate({scrollTop: 0}, 1000);
        return false;
    })

    $('a[href*="#"]').on('click', function(e){
        $('html,body').animate({
            scrollTop: $($(this).attr('href')).offset().top - 100
        },1000);
        e.preventDefault();
    });


    /*
    INPUT DYNAMIQUES
    */
    $('.input-checkbox span, .input-radio span, .input-switch span').on('click', function () {
       let input = $(this)[0].previousElementSibling
        input.checked = !input.checked;
    })

    /*
    ONGLETS
     */
    $('.onglet_title-item').on('click', function (e) {
        e.preventDefault();
        $('.onglet_title-item').removeClass('active')
        $(this).addClass('active');
        let title = $(this).data('title');
        $('.onglet_content-item').addClass('hidden');
        $('.onglet_content-item[data-content="'+title+'"]').removeClass('hidden');
    })

    /*
    TOGGLE PASSWORD
     */
    $('#show-pass').on('click', function () {
        //Change l'attribut du champ
        let attr = $(this).siblings('input').attr('type')
        let newAttr = (attr === 'password') ? 'text' : 'password';
        $(this).siblings('input').attr('type', newAttr)

        //Change le picto
        let classList = $(this).attr('class')
        let newClass = (classList === 'fas fa-eye cursor-pointer') ? 'fas fa-eye-slash cursor-pointer' : 'fas fa-eye cursor-pointer';
        $(this).attr('class', newClass);
    })

    /*
    ENLEVE STYLE P > IMG
    */
    $('p').has('img').css('all', 'unset');

    /*
    POPUP
     */
    $('.popup-open').on('click', function (e) {
        e.preventDefault();
        let id = $(this).data('id');
        $('#popup-'+id).fadeIn().css('display', 'grid')
    })
    /* Fermeture de la popup */
    $('.popup-close').on('click', function() {
        $('.popup').fadeOut();
    })
    $('.popup').on('click', function(e) {
        let popup = $('.popup-content');
        if (!popup.is(e.target) && popup.has(e.target).length === 0) {
            $('.popup').fadeOut();
        }
    });
    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            $('.popup').fadeOut();
        }
    });

});

/*
SLIDERS
 */
import Swiper, {Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs, Autoplay ]);

/* Slider hero */
const swiperHero = new Swiper('#slider-hero', {
    loop: true,
    autoplay: true,
    navigation: {
        nextEl: '.swiper-hero_next',
        prevEl: '.swiper-hero_prev',
    }
})

const swiperRef = new Swiper('#swiperRef', {
    loop: true,
    // autoplay: true,
    navigation: {
        nextEl: '.swiper-ref_next',
        prevEl: '.swiper-ref_prev',
    },
    breakpoints:{
        768: {
            slidesPerView :3,
            centeredSlides : true,
        },
        1024: {
            slidesPerView :5.5,
            centeredSlides : true,
        },
        1280: {
            slidesPerView :7.5,
            centeredSlides : true,
        }
    }
})

const slidesAmount = $('#swiper-offres').data('length');

const swiperOffres = new Swiper('#swiper-offres', {
    loop: (slidesAmount > 2),
    touchRatio: (slidesAmount > 2),
    autoplay: (slidesAmount > 2),
    slidesPerView :1.5,
    centeredSlides : (slidesAmount > 2),

    navigation: {
        nextEl: '.swiper-offres_next',
        prevEl: '.swiper-offres_prev',
    },
    breakpoints:{
        780: {
            slidesPerView :3,
            centeredSlides : (slidesAmount > 3),
            touchRatio: (slidesAmount > 3),
            autoplay: (slidesAmount > 3),
            loop: (slidesAmount > 3)
        },

        1600: {
            slidesPerView :5,
            centeredSlides : (slidesAmount > 5),
            touchRatio: (slidesAmount > 5),
            autoplay: (slidesAmount > 5),
            loop: (slidesAmount > 5)
        }
    }
})

